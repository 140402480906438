
// @ts-nocheck
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_common_json from "../locale/ar/common.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_user_json from "../locale/ar/user.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_informative_json from "../locale/ar/informative.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_product_json from "../locale/ar/product.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_footer_json from "../locale/ar/footer.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_checkout_json from "../locale/ar/checkout.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_common_json from "../locale/en/common.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_user_json from "../locale/en/user.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_informative_json from "../locale/en/informative.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_product_json from "../locale/en/product.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_footer_json from "../locale/en/footer.json";
import locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_checkout_json from "../locale/en/checkout.json";


export const localeCodes =  [
  "ar",
  "en"
]

export const localeMessages = {
  "ar": [{ key: "../locale/ar/common.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_common_json), cache: true },
{ key: "../locale/ar/user.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_user_json), cache: true },
{ key: "../locale/ar/informative.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_informative_json), cache: true },
{ key: "../locale/ar/product.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_product_json), cache: true },
{ key: "../locale/ar/footer.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_footer_json), cache: true },
{ key: "../locale/ar/checkout.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_ar_checkout_json), cache: true }],
  "en": [{ key: "../locale/en/common.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_common_json), cache: true },
{ key: "../locale/en/user.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_user_json), cache: true },
{ key: "../locale/en/informative.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_informative_json), cache: true },
{ key: "../locale/en/product.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_product_json), cache: true },
{ key: "../locale/en/footer.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_footer_json), cache: true },
{ key: "../locale/en/checkout.json", load: () => Promise.resolve(locale____w_Product_Module_Vue_Product_Module_Vue_locale_en_checkout_json), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "i18n_config_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "ar",
      "name": "عربي",
      "iso": "ar",
      "dir": "rtl",
      "files": [
        "locale/ar/common.json",
        "locale/ar/user.json",
        "locale/ar/informative.json",
        "locale/ar/product.json",
        "locale/ar/footer.json",
        "locale/ar/checkout.json"
      ]
    },
    {
      "code": "en",
      "iso": "en",
      "name": "English",
      "dir": "ltr",
      "files": [
        "locale/en/common.json",
        "locale/en/user.json",
        "locale/en/informative.json",
        "locale/en/product.json",
        "locale/en/footer.json",
        "locale/en/checkout.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locale",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "legacy": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "ar",
      "name": "عربي",
      "iso": "ar",
      "dir": "rtl",
      "files": [
        {
          "path": "locale/ar/common.json"
        },
        {
          "path": "locale/ar/user.json"
        },
        {
          "path": "locale/ar/informative.json"
        },
        {
          "path": "locale/ar/product.json"
        },
        {
          "path": "locale/ar/footer.json"
        },
        {
          "path": "locale/ar/checkout.json"
        }
      ]
    },
    {
      "code": "en",
      "iso": "en",
      "name": "English",
      "dir": "ltr",
      "files": [
        {
          "path": "locale/en/common.json"
        },
        {
          "path": "locale/en/user.json"
        },
        {
          "path": "locale/en/informative.json"
        },
        {
          "path": "locale/en/product.json"
        },
        {
          "path": "locale/en/footer.json"
        },
        {
          "path": "locale/en/checkout.json"
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

