import { default as _404A9FGMCVm7QMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/404.vue?macro=true";
import { default as accountK5XbrLw1crMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/account.vue?macro=true";
import { default as bundlesD7PlnRvA46Meta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/bundles.vue?macro=true";
import { default as cartIbee0Pfj0CMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/cart.vue?macro=true";
import { default as index0c4C6tVgUOMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/checkout/index.vue?macro=true";
import { default as paymentyrqugOvNAFMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/checkout/payment.vue?macro=true";
import { default as contactUsdfjzkYvXy9Meta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/contactUs.vue?macro=true";
import { default as _91id_93F0BgL00Q2FMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/custom/[id].vue?macro=true";
import { default as faqM06pNUQEqlMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/faq.vue?macro=true";
import { default as indexNC5CZJZV0OMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/index.vue?macro=true";
import { default as loginSmoXjve5WZMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/login.vue?macro=true";
import { default as order_45placedVeMwYNLD36Meta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/order-placed.vue?macro=true";
import { default as content0nDdK6i3R7Meta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/pages/[key]/content.vue?macro=true";
import { default as payment_45failednKdSlV5hyWMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/payment-failed.vue?macro=true";
import { default as processing_45transactionSswHl4ndpnMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/processing-transaction.vue?macro=true";
import { default as _91id_93oky2MpYcE6Meta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/products/[id].vue?macro=true";
import { default as indexgoe6OezzmSMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/products/index.vue?macro=true";
import { default as registerh3vexUaYXsMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/register.vue?macro=true";
import { default as resetbrAXVxWUocMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/reset.vue?macro=true";
import { default as staticocPI6sYlQkMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/static.vue?macro=true";
import { default as verifyCriZINZ7YSMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/verify.vue?macro=true";
import { default as wishlistUCvnGhoxUnMeta } from "/__w/Product-Module-Vue/Product-Module-Vue/pages/wishlist.vue?macro=true";
export default [
  {
    name: _404A9FGMCVm7QMeta?.name ?? "404",
    path: _404A9FGMCVm7QMeta?.path ?? "/404",
    meta: _404A9FGMCVm7QMeta || {},
    alias: _404A9FGMCVm7QMeta?.alias || [],
    redirect: _404A9FGMCVm7QMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/404.vue").then(m => m.default || m)
  },
  {
    name: accountK5XbrLw1crMeta?.name ?? "account",
    path: accountK5XbrLw1crMeta?.path ?? "/account",
    meta: accountK5XbrLw1crMeta || {},
    alias: accountK5XbrLw1crMeta?.alias || [],
    redirect: accountK5XbrLw1crMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/account.vue").then(m => m.default || m)
  },
  {
    name: bundlesD7PlnRvA46Meta?.name ?? "bundles",
    path: bundlesD7PlnRvA46Meta?.path ?? "/bundles",
    meta: bundlesD7PlnRvA46Meta || {},
    alias: bundlesD7PlnRvA46Meta?.alias || [],
    redirect: bundlesD7PlnRvA46Meta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/bundles.vue").then(m => m.default || m)
  },
  {
    name: cartIbee0Pfj0CMeta?.name ?? "cart",
    path: cartIbee0Pfj0CMeta?.path ?? "/cart",
    meta: cartIbee0Pfj0CMeta || {},
    alias: cartIbee0Pfj0CMeta?.alias || [],
    redirect: cartIbee0Pfj0CMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: index0c4C6tVgUOMeta?.name ?? "checkout",
    path: index0c4C6tVgUOMeta?.path ?? "/checkout",
    meta: index0c4C6tVgUOMeta || {},
    alias: index0c4C6tVgUOMeta?.alias || [],
    redirect: index0c4C6tVgUOMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: paymentyrqugOvNAFMeta?.name ?? "checkout-payment",
    path: paymentyrqugOvNAFMeta?.path ?? "/checkout/payment",
    meta: paymentyrqugOvNAFMeta || {},
    alias: paymentyrqugOvNAFMeta?.alias || [],
    redirect: paymentyrqugOvNAFMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/checkout/payment.vue").then(m => m.default || m)
  },
  {
    name: contactUsdfjzkYvXy9Meta?.name ?? "contactUs",
    path: contactUsdfjzkYvXy9Meta?.path ?? "/contactUs",
    meta: contactUsdfjzkYvXy9Meta || {},
    alias: contactUsdfjzkYvXy9Meta?.alias || [],
    redirect: contactUsdfjzkYvXy9Meta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/contactUs.vue").then(m => m.default || m)
  },
  {
    name: _91id_93F0BgL00Q2FMeta?.name ?? "custom-id",
    path: _91id_93F0BgL00Q2FMeta?.path ?? "/custom/:id()",
    meta: _91id_93F0BgL00Q2FMeta || {},
    alias: _91id_93F0BgL00Q2FMeta?.alias || [],
    redirect: _91id_93F0BgL00Q2FMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/custom/[id].vue").then(m => m.default || m)
  },
  {
    name: faqM06pNUQEqlMeta?.name ?? "faq",
    path: faqM06pNUQEqlMeta?.path ?? "/faq",
    meta: faqM06pNUQEqlMeta || {},
    alias: faqM06pNUQEqlMeta?.alias || [],
    redirect: faqM06pNUQEqlMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexNC5CZJZV0OMeta?.name ?? "index",
    path: indexNC5CZJZV0OMeta?.path ?? "/",
    meta: indexNC5CZJZV0OMeta || {},
    alias: indexNC5CZJZV0OMeta?.alias || [],
    redirect: indexNC5CZJZV0OMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginSmoXjve5WZMeta?.name ?? "login",
    path: loginSmoXjve5WZMeta?.path ?? "/login",
    meta: loginSmoXjve5WZMeta || {},
    alias: loginSmoXjve5WZMeta?.alias || [],
    redirect: loginSmoXjve5WZMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/login.vue").then(m => m.default || m)
  },
  {
    name: order_45placedVeMwYNLD36Meta?.name ?? "order-placed",
    path: order_45placedVeMwYNLD36Meta?.path ?? "/order-placed",
    meta: order_45placedVeMwYNLD36Meta || {},
    alias: order_45placedVeMwYNLD36Meta?.alias || [],
    redirect: order_45placedVeMwYNLD36Meta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/order-placed.vue").then(m => m.default || m)
  },
  {
    name: content0nDdK6i3R7Meta?.name ?? "pages-key-content",
    path: content0nDdK6i3R7Meta?.path ?? "/pages/:key()/content",
    meta: content0nDdK6i3R7Meta || {},
    alias: content0nDdK6i3R7Meta?.alias || [],
    redirect: content0nDdK6i3R7Meta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/pages/[key]/content.vue").then(m => m.default || m)
  },
  {
    name: payment_45failednKdSlV5hyWMeta?.name ?? "payment-failed",
    path: payment_45failednKdSlV5hyWMeta?.path ?? "/payment-failed",
    meta: payment_45failednKdSlV5hyWMeta || {},
    alias: payment_45failednKdSlV5hyWMeta?.alias || [],
    redirect: payment_45failednKdSlV5hyWMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: processing_45transactionSswHl4ndpnMeta?.name ?? "processing-transaction",
    path: processing_45transactionSswHl4ndpnMeta?.path ?? "/processing-transaction",
    meta: processing_45transactionSswHl4ndpnMeta || {},
    alias: processing_45transactionSswHl4ndpnMeta?.alias || [],
    redirect: processing_45transactionSswHl4ndpnMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/processing-transaction.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oky2MpYcE6Meta?.name ?? "products-id",
    path: _91id_93oky2MpYcE6Meta?.path ?? "/products/:id()",
    meta: _91id_93oky2MpYcE6Meta || {},
    alias: _91id_93oky2MpYcE6Meta?.alias || [],
    redirect: _91id_93oky2MpYcE6Meta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/products/[id].vue").then(m => m.default || m)
  },
  {
    name: indexgoe6OezzmSMeta?.name ?? "products",
    path: indexgoe6OezzmSMeta?.path ?? "/products",
    meta: indexgoe6OezzmSMeta || {},
    alias: indexgoe6OezzmSMeta?.alias || [],
    redirect: indexgoe6OezzmSMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: registerh3vexUaYXsMeta?.name ?? "register",
    path: registerh3vexUaYXsMeta?.path ?? "/register",
    meta: registerh3vexUaYXsMeta || {},
    alias: registerh3vexUaYXsMeta?.alias || [],
    redirect: registerh3vexUaYXsMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/register.vue").then(m => m.default || m)
  },
  {
    name: resetbrAXVxWUocMeta?.name ?? "reset",
    path: resetbrAXVxWUocMeta?.path ?? "/reset",
    meta: resetbrAXVxWUocMeta || {},
    alias: resetbrAXVxWUocMeta?.alias || [],
    redirect: resetbrAXVxWUocMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: staticocPI6sYlQkMeta?.name ?? "static",
    path: staticocPI6sYlQkMeta?.path ?? "/static",
    meta: staticocPI6sYlQkMeta || {},
    alias: staticocPI6sYlQkMeta?.alias || [],
    redirect: staticocPI6sYlQkMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/static.vue").then(m => m.default || m)
  },
  {
    name: verifyCriZINZ7YSMeta?.name ?? "verify",
    path: verifyCriZINZ7YSMeta?.path ?? "/verify",
    meta: verifyCriZINZ7YSMeta || {},
    alias: verifyCriZINZ7YSMeta?.alias || [],
    redirect: verifyCriZINZ7YSMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: wishlistUCvnGhoxUnMeta?.name ?? "wishlist",
    path: wishlistUCvnGhoxUnMeta?.path ?? "/wishlist",
    meta: wishlistUCvnGhoxUnMeta || {},
    alias: wishlistUCvnGhoxUnMeta?.alias || [],
    redirect: wishlistUCvnGhoxUnMeta?.redirect,
    component: () => import("/__w/Product-Module-Vue/Product-Module-Vue/pages/wishlist.vue").then(m => m.default || m)
  }
]