<template>
  <div
    class="flex h-screen w-screen flex-col items-center justify-center py-20"
  >
    <h1 class="relative text-[220px] font-extrabold text-grey-lightest">
      <img
        class="absolute -z-10 h-full w-full"
        src="~/assets/icons/error-bg.svg"
        alt="error-background"
      />
      <span class="z-10">
        {{ customError.code }}
      </span>
    </h1>

    <div class="flex max-w-[75%] flex-col items-center gap-4 text-center">
      <h2 class="text-2xl font-bold">{{ customError.type }}</h2>
      <p class="text-grey-lightest">
        {{ customError.message }}
      </p>
      <button
        class="btn-primary rounded-lg !bg-[#E05939] px-16 py-4"
        to="/"
        @click="customError.action"
      >
        {{ customError.actionLable }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from "~/store/common/userStore";
import paths from "./constants/paths";

type NuxtError = {
  statusCode: number;
  fatal: boolean;
  unhandled: boolean;
  statusMessage?: string;
  data?: any;
  cause?: any;
};

const userStore = useUserStore();

const props = defineProps({
  error: Object as () => NuxtError,
});

console.error(props.error);

const handleAuthError = () => {
  userStore.logout();
  reloadNuxtApp({ path: paths.home });
};

const handleGoBackToHome = () => {
  clearError({ redirect: paths.home });
};

const handleTryAgain = () => {
  clearError({ redirect: paths.home });
};

const customError = computed(() => {
  const errorCode = props.error?.statusCode?.toString() ?? "500";
  let errorType,
    message,
    actionLable,
    action = handleTryAgain;

  // Network Errors
  if (errorCode.startsWith("4")) {
    // Auth Error
    if (
      props.error?.data?.meta?.errors?.[0]?.type === "AuthenticationException"
    ) {
      errorType = "Authentication Exception";
      actionLable = "Logout and Try Again";
      message =
        "There was a problem with credentials. Please logout and try again.";
      action = handleAuthError;
    }
    //Generic Network Error
    else {
      errorType = "Network Error";
      actionLable = "Try Again";
      message =
        "There was a problem with your request. Please check your network connection.";
    }
  }
  // Server Errors
  else if (errorCode.startsWith("5")) {
    errorType = "Internal Server Error";
    actionLable = "Try Again";
    message = "Oops! Something went wrong on our end. Please try again later.";
  }
  // Default to generic error
  else {
    errorType = "Unknown Error";
    actionLable = "Try Again";
    message = "An unexpected error occurred.";
    action = handleGoBackToHome;
  }

  return {
    code: errorCode,
    type: errorType,
    message: message,
    actionLable: actionLable,
    action: action,
  };
});
</script>
